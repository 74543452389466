import React, {useEffect, useState} from 'react';
import {jwtDecode} from 'jwt-decode';
import { Navigate } from 'react-router-dom';
import { validateToken } from '../helpers/auth';

export default function PrivateRoute({children}){
    const authToken = localStorage.getItem('token');
    const [isTokenValid, setIsTokenValid] = useState(null);

    useEffect(() => {
        
        const checkTokenValidity = async () => {
            if (!authToken) {
                setIsTokenValid(false);
                return <Navigate to="/login" />;
            }

            try {
                const decodedToken = jwtDecode(authToken);
                if (decodedToken.exp * 1000 < Date.now()) {
                    console.log('Token expired. Logging out...');
                    localStorage.removeItem('token');
                    setIsTokenValid(false);
                    return <Navigate to="/login" />;
                }

                const isValid = await validateToken(authToken);
                setIsTokenValid(isValid);

                if (!isValid) {
                    localStorage.removeItem('token');
                }

            } catch (error) {
                console.error('Error decoding token:', error);
                localStorage.removeItem('token');
                setIsTokenValid(false);
            }
        };

        checkTokenValidity();
    }, [authToken]);

    if (isTokenValid === null) {
        return <div>Loading...</div>; // You can replace this with a proper loading spinner
    }

    return isTokenValid ? children : <Navigate to="/login" />;

    // if (!authToken) {
    //     return <Navigate to="/login" />;
    // }

    // try {
    //     const decodedToken = jwtDecode(authToken);

    //     if (decodedToken.exp * 1000 < Date.now()) {
    //         console.log('Token expired. Logging out...');
    //         localStorage.removeItem('token');
    //         return <Navigate to="/login" />;
    //     }
      
    //     return children;

    // } catch (error) {
    //     console.error('Error decoding token:', error);
    //     return <Navigate to="/login" />;
    // }
}