import React, { useState, useEffect, useRef } from 'react';
import { HiOutlineBell } from "react-icons/hi2";
import axiosInstance from "../../config/axiosInstance";

import NotificationList from "../../components/Notifications/NotificationList";


export default function NotificationIcon({notificationCount, getNotifications, notifications}) {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);


    const toggleDropdown = () => {
        setIsDropdownOpen(prev => !prev);
    };

    // Close dropdown when clicking outside
    useEffect(() => {

        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsDropdownOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);



    return (
        <div className="relative mr-5" ref={dropdownRef}>
            {/* Bell Icon */}
            <div className="cursor-pointer">
                <HiOutlineBell
                    className="font-bold text-3xl"
                    onClick={toggleDropdown}
                />

                {/* Red Circle Badge with Number */}
                {notificationCount > 0 ? (
                    <div
                        className="absolute top-0 left-0 w-4 h-4 bg-red-500 text-white text-xs flex items-center justify-center rounded-full"
                        onClick={toggleDropdown}>
                        {notificationCount}
                    </div>) : null
                }
            </div>

            {/* Dropdown */}
            {isDropdownOpen &&
                (

                    <div
                        className="w-full h-full bg-gray-800 bg-opacity-90 top-0 right-0 z-10 overflow-y-auto overflow-x-hidden fixed sticky-0">
                        <div  ref={dropdownRef}  className="w-full absolute z-10 right-0 h-full overflow-x-hidden transform translate-x-0 transition ease-in-out duration-700"  id="notification">
                            <div  className="w-full md:w-3/6 lg:w-2/6 top-0 bg-gray-50 h-screen overflow-y-auto p-8 absolute right-0">
                                <div className="flex items-center justify-between">
                                    <p  tabIndex="0" className="focus:outline-none text-2xl font-semibold leading-6 text-gray-800">
                                        Notifications
                                    </p>
                                    <button  role="button" aria-label="close modal"  className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 rounded-md cursor-pointer"  onClick={toggleDropdown}>
                                        <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M18 6L6 18"
                                                stroke="#4B5563"
                                                strokeWidth="1.25"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                            <path
                                                d="M6 6L18 18"
                                                stroke="#4B5563"
                                                strokeWidth="1.25"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                    </button>
                                </div>

                                {/* Updated to make space between notifications.js and link */}
                                <div className="flex flex-col justify-between h-full">
                                    <div className="mt-4 space-y-4">

                                        {notifications.length > 0 ? (<NotificationList notifications={notifications} />)
                                            : (<div className="p-10 text-center text-gray-600 text-sm">You have no notifications at the moment!</div>) }

                                    </div>

                                    {/* "Show all notifications.js" link positioned at the bottom */}
                                    {/*<div className="mt-4">*/}
                                    {/*    <a*/}
                                    {/*        href="#"*/}
                                    {/*        className="text-center block py-5 text-gray-400 underline text-sm"*/}
                                    {/*    >*/}
                                    {/*        Show all notifications*/}
                                    {/*    </a>*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                        </div>
                    </div>


                )
            }


        </div>
    )
}