import React from 'react';
import { HiChatBubbleBottomCenterText } from "react-icons/hi2";
import {FRONTEND_URL} from "../../config/constants";

import {isRead} from "../../helpers/notificationHelpers";

export default function ProjectMoreMessages ({key, notification, unreadCount})  {

    const handleClick = async() => {
        try{
            const read = await isRead(notification.project.id)
            if(read.message === "success"){
                window.location.href = `/manage/project/${notification.project.id}`;
            }
        } catch (error){
            console.log(error);
        }
    }

    return (
        <>
            <div className={`w-full p-3 rounded flex ${unreadCount > 0 ? ' bg-green-100 ' : ' bg-white'}`}>
                <div tabIndex="0" aria-label="post icon" role="img"  className="focus:outline-none w-8 h-8 flex items-center"><HiChatBubbleBottomCenterText /></div>

                <div className="pl-3 cursor-pointer" onClick={handleClick} >
                    <p tabIndex="0" className="focus:outline-none text-sm ">
                        You have {unreadCount} messages on {notification.project.name}
                    </p>
                </div>

            </div>
        </>
    )
}