
export default function PageLoadingSpinner(){
    return(
        <div className="flex justify-center items-center w-full h-full">
            <style>
                {`
                @keyframes loader-rotate {
                0% {
                    transform: rotate(0);
                }
                100% {
                    transform: rotate(360deg);
                }
                }
                .loader {
                border-right-color: transparent;
                animation: loader-rotate 1s linear infinite;
                }
                `}
            </style>
            <div class="w-12 h-12 border-2 border-gray-600 rounded-full loader"></div>
        </div>
    )
}