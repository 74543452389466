import {React, useState, useEffect} from 'react';
import {Link, useLocation} from "react-router-dom";
import AllProjectsTable from '../../components/project/AllProjectsTable';


import PageTitle from "../../components/PageTitle";
import axiosInstance from '../../config/axiosInstance';

export default function AllProjects(){
    const [projects, setProjects] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [brands, setBrands] = useState("");
    const [designers, setDesigners] = useState([]);

    const [status, setStatus] = useState("");
    const [company, setCompany] = useState("");
    const [brand, setBrand] = useState("");
    const [designer, setDesigner] = useState("");


    const [filtered, setFiltered] = useState(false);

    useEffect(() => {
        getProjects();
        getBrands();
        getDesigners()
    }, [])

    useEffect(() => {
        getProjects();
        if(status !== "" || brand !== "" || company !== "" || designer !== ""){
            setFiltered(true)
        }
    }, [status, brand, company, designer])

    

    const getProjects = async () => {
        setIsLoading(true)
        try {
            const response = await axiosInstance.get(`clients/projects/filter?status=${status}&company=${company}&brand=${brand}&designer=${designer}`);
            setProjects(response.data.data)
        } catch(error){
            console.log(error);
        } finally {
            setIsLoading(false)
        }
    }

    const getBrands = async () => {
        try {
            const response = await axiosInstance.get(`clients/brands`);
            setBrands(response.data.data.brands);
        } catch(error){
            console.log(error)
        }
    }

    const getDesigners = async () => {
        try{
            const response = await axiosInstance.get(`admin/users`);
            console.log(response.data.data);
            setDesigners(response.data.data);
        } catch(error){
            console.log(error);
        }
    }

    const handleFilter = (e, type) => {
        if(type === "status"){
            if(e.target.value === "none"){
                setStatus("");
            } else {
                setStatus(e.target.value)
            }
        }

        if(type === "designer"){
            if(e.target.value === "none"){
                setDesigner("")
            } else {
                setDesigner(e.target.value)
            }
        }

        if(type === "brand"){
            if(e.target.value === "none"){
                setBrand("");
            } else {
                setBrand(e.target.value);
            }
        }
    }

    const handleClearFilter = () => {
        setStatus("");
        setBrand("");
        setCompany("");
        setDesigner("")
        setFiltered(false)
    }

    return(
        <>

        <PageTitle title={"Projects"} />

        <div className="flex justify-between">
            <h2 className="font-bold text-xl">Projects</h2>


            <div className='flex justify-between items-center gap-3'>

                <div>
                    {filtered &&
                        <span className='inline-block w-24 text-right text-sm cursor-pointer underline text-blue-900'
                              onClick={handleClearFilter}>clear filter</span>}
                </div>

                <select className='block w-full p-2 border rounded-md' onChange={(e) => handleFilter(e, 'status')}
                        value={status}>
                    <option value="none">Select Status</option>
                    <option value="0">Pending</option>
                    <option value="1">In progress</option>
                    <option value="2">Ready for feedback</option>
                    <option value="3">Completed</option>
                </select>

                <select className='block w-full p-2 border rounded-md' onChange={(e) => handleFilter(e, 'brand')}
                        value={brand}>
                    <option value="none">Select Brand</option>
                    {brands.length > 0 ? (
                        brands.map(brand => {
                            return (
                                <option value={`${brand.id}`}>{brand.brand_name}</option>
                            )
                        })
                    ) : (
                        <option>No brands selected</option>
                    )}
                </select>

                <select className='block w-full p-2 border rounded-md' onChange={(e) => handleFilter(e, 'designer')}
                        value={designer}>
                    <option value="none">Select Designer</option>
                    {designers.length > 0 ? (
                        designers.map(designer => {
                            return (
                                <option value={`${designer.id}`}>{designer.firstname} {designer.lastname}</option>
                            )
                        })
                    ) : (
                        <option>No brands selected</option>
                    )}
                </select>


            </div>
        </div>


            {!isLoading && <AllProjectsTable projects={projects} isLoading={isLoading}/>}


        </>
    )
}