import React from 'react'
import {Routes, Route, Navigate} from 'react-router-dom';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



import AuthRoutes from './routes/AuthRoutes';
import MainRoutes from './routes/MainRoutes';

import UserProvider from './context/UserProvider';

function App() {
  return (
    <>
    <ToastContainer />
    <Routes>
        <Route path="/" element={<Navigate to="/login" />}/>
    </Routes>

    
    <UserProvider>
    
      <AuthRoutes />
      <MainRoutes />
    
    </UserProvider>
    
    
    
    
    
    </>
  );
}

export default App;
