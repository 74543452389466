import React, { Component } from 'react';
import {Link, useLocation} from "react-router-dom";

export default function AllBrandsTable({brands}){
    return(
        <>

            <div className="rounded-lg border border-gray-200">
            <div className="overflow-x-auto rounded-t-lg">
                <table className="min-w-full divide-y-2 divide-gray-200 bg-white">
                <thead className="text-left">
                    <tr className="uppercase text-xs">
                        <th className="whitespace-nowrap px-4 py-2 text-gray-700">Brand Name</th>
                        <th className="whitespace-nowrap px-4 py-2 text-gray-700">Industry</th>
                        <th className="whitespace-nowrap px-4 py-2 text-gray-700">Company Name</th>
                        <th className="whitespace-nowrap px-4 py-2 text-gray-700">Active Projects</th>
                        <th className="whitespace-nowrap px-4 py-2 text-gray-700">Pending Projects</th>
                    </tr>
                </thead>

                <tbody className="divide-y divide-gray-200">
                {brands && brands.length > 0 ? (
                    brands.map(brand => (
                        <tr className="capitalize" key={brand.id}>
                            <td className="whitespace-nowrap px-4 py-2 font-medium underline"><Link to={`/manage/brand/${brand.id}`}>{brand.brand_name}</Link></td>
                            <td className="whitespace-nowrap px-4 py-2">{brand.industry}</td>
                            <td className="whitespace-nowrap px-4 py-2">{brand.company.name}</td>
                            <td className="whitespace-nowrap px-4 py-2">{brand.activeProjects} active projects</td>
                            <td className="whitespace-nowrap px-4 py-2">{brand.pendingProjects} pending projects</td>
                        </tr>
                    ))
                ) : (
                    <tr>
                        <td colSpan="6" className="px-4 py-2 text-center">No companies found.</td>
                    </tr>
                )}
                    
                </tbody>
                </table>
            </div>

            <div className="rounded-b-lg border-t border-gray-200 px-4 py-2">
                <ol className="flex justify-end gap-1 text-xs font-medium">
                <li>
                    <a href="#" className="inline-flex size-8 items-center justify-center rounded border border-gray-100 bg-white text-gray-900 rtl:rotate-180" >
                    <span className="sr-only">Prev Page</span>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd"/>
                    </svg>
                    </a>
                </li>

                <li>
                    <a href="#" className="block size-8 rounded border border-gray-100 bg-white text-center leading-8 text-gray-900">1</a>
                </li>

                <li className="block size-8 rounded border-blue-600 bg-blue-600 text-center leading-8 text-white">
                    2
                </li>

                <li>
                    <a
                    href="#"
                    className="block size-8 rounded border border-gray-100 bg-white text-center leading-8 text-gray-900"
                    >
                    3
                    </a>
                </li>

                

                <li>
                    <a href="#" className="inline-flex size-8 items-center justify-center rounded border border-gray-100 bg-white text-gray-900 rtl:rotate-180">
                    <span className="sr-only">Next Page</span>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                    </svg>
                    </a>
                </li>
                </ol>
            </div>
            </div>

        </>
    )
}