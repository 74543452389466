import React, {useContext, useEffect, useState, useRef} from "react";
import UserContext from "../../context/UserContext";
import {Link, useLocation} from "react-router-dom";
import axiosInstance from "../../config/axiosInstance";
import NotificationIcon from "./NotificationIcon";


export default function Header(){
    const { user, logout, getUserData } = useContext(UserContext)
    const [isProfileMenuVisible, setProfileMenuVisibility] = useState(false);
    const [notifications, setNotifications] = useState("");
    const [notificationCount, setNotificationCount] = useState(false);

    const userDetails = {
        firstname: user ? user.firstname : '',
        user: user? user : null,
        lastname: user? user.lastname : ''
    }

    useEffect(() => {
        getUserData();
    }, [])

    const getNotifications = async () => {
        try{
            const response = await axiosInstance.get('/notifications');
            setNotifications(response.data.data)
            setNotificationCount(response.data.data.length);
        } catch(error){
            console.log(error);
        }
    }

    useEffect(() => {
        getNotifications();
        const intervalId = setInterval(() => {
            getNotifications();
        }, 3000);

        // Cleanup the interval if the component unmounts
        return () => clearInterval(intervalId);
    }, [])

    const profileMenuRef = useRef();

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (profileMenuRef.current && !profileMenuRef.current.contains(event.target)) {
                closeProfileMenu();
            }
        };
        document.addEventListener("click", handleClickOutside);

        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    const showProfileMenu = () => {
        setProfileMenuVisibility(!isProfileMenuVisible);
    };

    const closeProfileMenu = () => {
        setProfileMenuVisibility(false);
    };



    return(
        <>
        <header className='bg-white flex justify-between px-5 py-2 border-b w-full'>
            <div></div>
            <div className="flex items-center">
                <div><NotificationIcon notificationCount={notificationCount} getNotifications={getNotifications} notifications={notifications} /></div>
                <div className="relative" ref={profileMenuRef}>

                    <div className="py-3 px-5 hover:cursor-pointer rounded-lg"
                         onClick={showProfileMenu}>Hi {userDetails.firstname}</div>

                    <div className={`${
                        isProfileMenuVisible ? 'absolute' : 'hidden'
                    } end-0 z-10 mt-2 w-56 rounded-md border border-gray-100 bg-white shadow-lg`}>
                        <div className="p-2 text-center">
                            <a href="/manage/profile/settings"
                               className="block rounded-lg px-4 py-2 text-sm text-gray-500 hover:bg-gray-50 hover:text-gray-700"
                               role="menuitem">
                                Settings
                            </a>
                            <a href="#" onClick={logout}
                               className="block rounded-lg px-4 py-2 text-sm text-gray-500 hover:bg-gray-50 hover:text-gray-700"
                               role="menuitem">
                                Sign Out
                            </a>
                        </div>
                    </div>
                </div>
            </div>


        </header>
        </>
    )
}