import {React, useEffect, useState} from 'react';
import { Link, useParams } from 'react-router-dom';

import LoadingSpinner from '../isLoading';

export default function AllProjectsTable({projects, isLoading}){
    return(
        <>
            <div className='mt-5'>
                <table class="min-w-full divide-y-2 divide-gray-200 bg-white">
                    <thead className="text-left">
                    <tr className="uppercase text-sm">
                        <th className="whitespace-nowrap px-4 py-2 text-gray-700">Project Name</th>
                        <th className="whitespace-nowrap px-4 py-2 text-gray-700">Type</th>
                        <th className="whitespace-nowrap px-4 py-2 text-gray-700">Estimated Delivery</th>
                        <th className="whitespace-nowrap px-4 py-2 text-gray-700">Brand</th>
                        <th className="whitespace-nowrap px-4 py-2 text-gray-700">Status</th>
                        <th className="whitespace-nowrap px-4 py-2 text-gray-700">Created Date</th>
                    </tr>
                    </thead>

                    <tbody class="divide-y divide-gray-200">
                        {isLoading && <tr><td colSpan={6}><div className='py-10'><LoadingSpinner /></div></td></tr>}
                        
                        {!isLoading && projects.length > 0 ? (
                            projects.map(project => {

                                const project_status = () => {
                                    
                                    if(project.status === 0){
                                        return <span className='uppercase text-xs bg-red-700 text-white px-2 py-1 rounded-full font-bold'>pending</span>
                                    } else if(project.status === 1){
                                        return <span className='uppercase text-xs bg-yellow-700 text-white px-2 py-1 rounded-full font-bold'>In Progress</span>
                                    } else if(project.status === 2){
                                        return <span className='uppercase text-xs bg-purple text-white px-2 py-1 rounded-full font-bold'>Design ready for feedback</span>
                                    } else {
                                        return <span className='uppercase text-xs bg-green-700 text-white px-2 py-1 rounded-full font-bold'>Completed</span>
                                    }
                                }

                                const project_type = () => {
                                    if(project.task_type === "graphic"){
                                        return <span className='uppercase text-xs bg-purple text-white px-2 py-1 rounded-full font-bold'>Graphic</span>
                                    } else {
                                        return <span className='uppercase text-xs bg-green-700 text-white px-2 py-1 rounded-full font-bold'>Video</span>
                                    }
                                }

                                const estdelivery = () => {
                                    if(project.due_date === null){
                                        return 'N/A'
                                    } else {
                                        const parsedDateTime = new Date(project.due_date);
                                        return parsedDateTime.toLocaleString(); 
                                    }
                                }

                                const createdAt = () => {
                                    const parsedDateTime = new Date(project.createdAt);
                                    return parsedDateTime.toLocaleString();
                                }

                                return(
                                    <tr className='text-sm' key={project.id}>
                                        <td class="whitespace-nowrap px-4 py-2"><Link to={`/manage/project/${project.id}`} className='font-bold'>{project.project_name}</Link></td>
                                        <td class="whitespace-nowrap px-4 py-2 text-gray-700">{project_type()}</td>
                                        <td class="whitespace-nowrap px-4 py-2 text-gray-700">{estdelivery()}</td>
                                        <td class="whitespace-nowrap px-4 py-2 text-gray-700">{project.brand.brand_name}</td>
                                        <td class="whitespace-nowrap px-4 py-2 text-gray-700">{project_status()}</td>
                                        <td class="whitespace-nowrap px-4 py-2 text-gray-700">{createdAt()}</td>
                                    </tr>
                                )
                            })
                        ) : (
                            <tr>
                                <td colSpan={6}><div className='text-center py-4 font-bold text-gray-400'>No Projects found</div></td>
                            </tr>
                        )}
                        
                    </tbody>
                </table>
        
            </div>
        
        </>
    )
}