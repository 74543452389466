// ErrorBoundary.js

import React, { Component } from 'react';

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, error: null };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true, error };
    }

    componentDidCatch(error, errorInfo) {
        // You can log the error to an error reporting service
        console.error("Error caught by ErrorBoundary:", error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <div className="flex justify-center items-center animate-fade-in fixed  inset-0 z-40 bg-gray-100 bg-opacity-50 backdrop-blur-md px-5">
            <div className="w-1/4 bg-white px-5 py-5 rounded-lg shadow-xl flex flex-col justify-center items-center">
                <h2 className="text-lg">Something went wrong</h2>
                {/* <h2 className="text-lg">Hello this is the error</h2> */}

            </div>
            
        </div>
            );
        }

        return this.props.children; 
    }
}

export default ErrorBoundary;
