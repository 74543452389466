import React, {useEffect, useState} from 'react'
import UserContext from "./UserContext";
import { jwtDecode } from "jwt-decode";
import axiosInstance from '../config/axiosInstance';

const UserProvider = ({children}) => {
    const [user, setUser] = useState({});

    useEffect(() => {
        getUserData();
    }, [])

    const login = (userData) => {
        setUser(userData);
    }

    const logout = async () => {
        try{
            const response = await axiosInstance.post(`/admin/user/clear-token`, user);
            console.log(response);
        }catch(error){
            console.log(error)
        }
        localStorage.clear();

        window.location.href="/login"
    }

    const getUserData = () => {
        try{
            const storedUser = localStorage.getItem('token');
            if (storedUser) {
                const decodedToken = jwtDecode(storedUser, '1010');
                const parsedUser = {
                    userId: decodedToken.user_id,
                    firstname: decodedToken.firstname,
                    lastname: decodedToken.lastname,
                    email: decodedToken.email,
                    role: decodedToken.role
                }
                login(parsedUser);
            }
        } catch (error) {
            console.log("This is the error")
        }
    }


    return(

        <UserContext.Provider value={{user, logout, getUserData}}>
            {children}
        </UserContext.Provider>

    )
}

export default UserProvider;