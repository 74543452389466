import React from 'react';

export default function ProjectCompletedRow() {
    return (
        <>
            <div className="w-full p-3 mt-8 bg-green-100 rounded flex items-center">
                <div tabIndex="0" aria-label="success icon" role="img"
                     className="focus:outline-none w-8 h-8 border rounded-full border-green-200 flex flex-shrink-0 items-center justify-center">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M6.66674 10.1147L12.7947 3.98599L13.7381 4.92866L6.66674 12L2.42407 7.75733L3.36674 6.81466L6.66674 10.1147Z"
                            fill="#047857"/>
                    </svg>
                </div>
                <div className="pl-3 w-full">
                    <div className="flex items-center justify-between">
                        <p tabIndex="0"
                           className="focus:outline-none text-sm leading-none text-green-700">Design
                            sprint completed</p>
                        <p tabIndex="0"
                           className="focus:outline-none focus:text-indigo-600 text-xs leading-3 underline cursor-pointer text-green-700">View</p>
                    </div>
                </div>
            </div>

        </>
    )
}