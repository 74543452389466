import { PDFViewer } from '@react-pdf/renderer';
import InvoicePDF from '../components/InvoicePDF';

export default function TestInvoicePage(){
    return(
        <>
            <h1>Test Invoice Page</h1>
        
            <PDFViewer style={{ width: '100%', height: '100vh' }}>
                <InvoicePDF />
            </PDFViewer>
        </>
    )
}
