import {React, useState, useEffect} from "react";
import axiosInstance from "../../config/axiosInstance";
import {Link, useLocation} from "react-router-dom";

import PageTitle from "../../components/PageTitle";
import AllBrandsTable from "../../components/brands/AllBrandsTable";

export default function AllBrands(){

    const [brands, setBrands] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    

    useEffect(() => {
        getBrands();
    }, [])

    const getBrands = async () => {
        try{
            setIsLoading(true);
            const response = await axiosInstance.get('clients/brands');
            setBrands(response.data.data.brands);
            console.log(response.data.data.brands)
        } catch(error){
            console.log(error)
        }finally{
            setIsLoading(false)
        }
    }

    return(
        <>
        <PageTitle title={"Brands"} />

        <div className="flex justify-between">
            <h2 className="font-bold text-xl">Brands</h2>
        </div>

        <div className="mt-5">
            {isLoading ? <>Loading...</> : <AllBrandsTable brands={brands} />}
        </div>
        </>
    )
    
} 