import React from 'react';
import moment from 'moment-timezone';

export function timeDistanceToNow(createdAt) {
    const timeZone = sessionStorage.getItem('locale') || 'America/Toronto';

    // Parse the createdAt string as a local time if it’s already in local time
    const localDate = moment.tz(createdAt, timeZone);


    // Return the relative time from now
    return localDate.fromNow();
}