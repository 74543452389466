import { Outlet } from 'react-router-dom';

import Sidebar from './Sidebar';
import Header from './Header';

export default function DefaultLayout(){
    return(
        <>
        <div className='flex h-screen bg-gray-50 antialiased text-gray-700 text-base'>
            <Sidebar />
            <div className='flex flex-col flex-1 w-full overflow-y-auto'>
                <Header/>
                <div className='px-5 py-6'>
                    <Outlet/>
                </div>
            </div>
        </div>
        
        
        </>
    )
}