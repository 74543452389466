import React from 'react';
import axiosInstance from "../config/axiosInstance";


export async function isRead(project_id){
    try{
        const response = await axiosInstance.get(`/notifications/is-read/${project_id}`);
        return response.data;
    } catch(error){
        console.log(error);
        return error;
    }
}