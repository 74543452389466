import React, { lazy, Suspense } from 'react';
import { Route, Routes } from "react-router-dom";

const Login = lazy(() => import('../pages/auth/Login'));

export default function AuthRoutes(){
    return(
        <Suspense fallback={<div className='flex items-center justify-center w-full h-screen font-bold text-lg'>Loading..</div>}>
            <Routes>
                <Route path="/login" element={<Login />} />
            </Routes>
        </Suspense>
        
    )
}
