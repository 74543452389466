import React from 'react';
import { Document, Page, Text, View, StyleSheet, PDFViewer } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    padding: 20,
  },
  section: {
    marginBottom: 20,
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  subtitle: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  text: {
    fontSize: 12,
    marginBottom: 5,
  },
  table: {
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    width: '100%',
  },
  tableRow: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderRightWidth: 0,
    borderLeftWidth: 0,
  },
  tableColHeader: {
    width: '33.33%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    padding: 5,
  },
  tableCol: {
    width: '33.33%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    padding: 5,
  },
});

const InvoicePDF = ({ invoiceDetails, companyDetails, invoice }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Text style={styles.title}>Invoice</Text>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <Text style={styles.tableColHeader}>Description</Text>
            <Text style={styles.tableColHeader}>Rate</Text>
            <Text style={styles.tableColHeader}>Total</Text>
          </View>
          {invoiceDetails.map((invoiceDetail, index) => (
            <View key={index} style={styles.tableRow}>
              <Text style={styles.tableCol}>{invoiceDetail.description}</Text>
              <Text style={styles.tableCol}>{companyDetails.default_currency} {invoiceDetail.subtotal}</Text>
              <Text style={styles.tableCol}>{companyDetails.default_currency} {invoiceDetail.total}</Text>
            </View>
          ))}
        </View>
        <View style={styles.section}>
          <Text style={styles.subtitle}>Payment Details</Text>
          <Text style={styles.text}>Subtotal: {companyDetails.default_currency} {invoice.invoice_subtotal}</Text>
          <Text style={styles.text}>Total: {companyDetails.default_currency} {invoice.invoice_value}</Text>
        </View>
      </View>
    </Page>
  </Document>
);

export default InvoicePDF;
